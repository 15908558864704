import React, { useState, useRef, useEffect } from "react";
import NavBar from "../../components/navbar";
import "../../components/homevideo.css";
import LHFmaincontent from "../../template/LalithHapangamaFoundation/LHFmaincontent";
//import vote from "../../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Footer3CS from "../../components/footerbar";
import Arrow from "../../template/ScrollToTopButton";
import {
  Parallax,
  ParallaxProvider,
  ParallaxBannerLayer,
  ParallaxBanner,
} from "react-scroll-parallax";
import { Helmet } from "react-helmet";

const LalithHapamgamaFoundation = () => {
  //  BestWeb - start
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 600);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  //  BestWeb - end

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setScrollY = useState(0)[1];
  const mainRef = useRef(null);

  const handleClick = () => {
    setScrollY(100);
    mainRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ParallaxProvider>
      <div style={{ backgroundColor: "#121718" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Lalith Hapangama Foundation | Lalan Group Sri Lanka</title>
          <meta
            name="title"
            content="Lalith Hapangama Foundation | Lalan Group Sri Lanka"
          />
          <meta
            name="description"
            content="The initiatives that the Foundation aims to work towards focus on the overarching themes of Cancer Prevention and Awareness, Education, infrastructure and Mental Health. "
          />
          <meta name="keywords" content="Lalith Hapangama Foundation" />
          <link
            rel="canonical"
            href="https://www.lalangroup.com/sustainability/lalith-hapangama-foundation/"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Lalith Hapangama Foundation - Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:url"
            content="https://www.lalangroup.com/sustainability/lalith-hapangama-foundation/"
          />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
        </Helmet>

        <NavBar />
        <div style={{ position: "relative" }}>
          <ParallaxBanner style={{ aspectRatio: "2 / 1.124", height: "100vh" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/top-banner7.jpg"
              speed={0}
            />
          </ParallaxBanner>
          <div className="top-font-block">
            <Parallax speed={10}>
              <img
                className="video-font-png"
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/lalith_hapugama_foundation.png"
                alt="font"
              />
            </Parallax>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: "13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="mouse-color">
              <img
                onClick={handleClick}
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/uploads/images/gif-mouse.gif"
                alt="button"
                className="mouse-button"
                style={{ height: "90px", zIndex: 10 }}
              />
            </div>
          </div>
        </div>

        <div ref={mainRef}>
          {/* Main content */}
          <div
            style={{
              marginLeft: "130px",
              marginRight: "170px",
              marginTop: "30px",
            }}
          >
            <LHFmaincontent />

            {/* BestWeb - start */}
            {/* <div>
              <a
                href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                target="_blank"
              >
                <img
                  className="logopic"
                  src={vote}
                  alt="logo"
                  style={{
                    marginTop: isMobile ? "70px" : "20px",
                    marginBottom: isMobile ? "0px" : "70px",
                    marginLeft: isMobile ? "-95px" : "0px",
                  }}
                />
              </a>
            </div> */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* BestWeb - start */}
              {/* <div>
                <a
                  href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
                  target="_blank"
                >
                  <img
                    className="logopic"
                    src={vote}
                    alt="logo"
                    style={{
                      marginTop: isMobile ? "85px" : "35px",
                      marginBottom: isMobile ? "0px" : "70px",
                      marginLeft: isMobile ? "-95px" : "0px",
                    }}
                  />
                </a>
              </div> */}
              {/* BestWeb - end */}

              {/* TopWeb - start */}
              <div>
                <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
                  <img
                    style={{
                      marginTop: isMobile ? "80px" : "30px",
                      marginBottom: isMobile ? "0px" : "70px",
                      marginLeft: "30px",
                    }}
                    className="logopic-2"
                    src={TopWeb}
                    alt="logo"
                  />
                </a>
              </div>
              {/* TopWeb - end */}
            </div>

            {/* BestWeb - end */}
          </div>
        </div>
        <div style={{ marginTop: "100px" }}>
          <Footer3CS />
        </div>
        <Arrow />
      </div>
    </ParallaxProvider>
  );
};

export default LalithHapamgamaFoundation;
