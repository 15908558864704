import React  from "react";
import "./LHFmaincontent.css";
import { Parallax, ParallaxProvider, ParallaxBannerLayer, ParallaxBanner } from 'react-scroll-parallax';

const LHFmaincontent = () => {


    return (
      <ParallaxProvider>
        <div className="lalith">
          <br />
          <br />

          <div>
            <p className="para" style={{ marginTop: "70px" }}>
              The Lalith Hapangama Foundation is the charitable organisation
              responsible for fulfilling the Hapangama family's philanthropic
              initiatives, serving as the charitable arm of the Lalan Group.
              Philanthropy has been integral to the Hapangama family's core
              values, with generations of the family involved in wide ranging
              humanitarian efforts in the past. The family's continuous support
              for those who need it most, predominantly through initiatives
              based on education, religion, medical assistance, and social
              empowerment, are amplified through the establishment of the
              Foundation. The Foundation ensures that the fundamental values of
              philanthropy, service, and generosity, which are of vital
              importance to the family, will assist and empower Sri Lankans for
              generations to come.
              <br />
              <br />
              The initiatives that the Foundation aims to work towards focus on
              the overarching themes of Cancer Prevention and Awareness,
              Education, and Mental Health. Programs carried out in this regard
              assist and empower beneficiaries from within the Lalan Group, as
              well as everyday Sri Lankans beyond the scope of the Group.
              <br />
              <br />
              The Foundation's efforts entail scholarship funds for students
              across all levels of education, the establishment of vocational
              training institutes and industry employment opportunities, female
              SME/self-entrepreneurship programs, mobile cancer screening units
              and widespread cancer awareness programs, as well as the
              establishment of mental health facilities comprising counselling
              services, gender-based violence support units, special needs
              assistance units, and rehabilitation services to provide greater
              access to mental health support outside of major cities.
              <br />
              <br />
            </p>
          </div>

          {/* 
        <a href="/sustainability/lalith-hapangama-foundation/supply-and-conservation-of-safe-water/"><button>Supply and Conservation of Safe water</button></a> <br/><br/>
        <a href="/sustainability/lalith-hapangama-foundation/infrastructure-redevelopment-projects/"><button>Infrastructure Iedevelopment Projects</button></a> <br/><br/>
        <a href="/sustainability/lalith-hapangama-foundation/dedicated-health-care-initiatives/"><button>Dedicated Health Care Initiatives</button></a> <br/><br/>
        <a href="/sustainability/lalith-hapangama-foundation/supporting-education/"><button>Supporting Education</button></a> <br/><br/>
        */}

          {/* ......... 1 ...................................................................................... */}

          <div style={{ marginTop: "200px" }}>
            <div className="two-column">
              <div className="column-2">
                {/* Column 1 */}
                <ParallaxBanner style={{ aspectRatio: "2 / 1.4" }}>
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/03.jpg"
                    speed={2}
                    style={{ backgroundSize: "cover" }}
                  />
                </ParallaxBanner>
                <br />
                <a
                  href="/sustainability/lalith-hapangama-foundation/supply-and-conservation-of-safe-water/"
                  className="topic"
                >
                  Supply and Conservation of Safe water
                </a>
                <p className="para">
                  Safe water is a basic necessity and is a resource that is
                  increasingly scarce. <br />
                  <br />
                </p>
                <p className="readmore">
                  <a href="/sustainability/lalith-hapangama-foundation/supply-and-conservation-of-safe-water/">
                    Read More
                  </a>
                </p>
              </div>

              <div className="column-2">
                <div style={{ marginTop: "100px" }}>
                  {/* Column 2 */}
                  <Parallax speed={20}>
                    <ParallaxBanner style={{ aspectRatio: "2 / 1.4" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/04.jpg"
                        speed={2}
                        style={{ backgroundSize: "cover" }}
                      />
                    </ParallaxBanner>
                    <br />
                    <a
                      href="/sustainability/lalith-hapangama-foundation/infrastructure-redevelopment-projects/"
                      className="topic"
                    >
                      Infrastructure Redevelopment Projects&nbsp;&nbsp;
                    </a>
                    <p className="para">
                      Whilst looking after the welfare of the communities on our
                      estates, we also actively contribute to the redevelopment
                      of infrastructure in the surrounding villages; <br />
                      <br />
                    </p>
                    <p className="readmore">
                      <a href="/sustainability/lalith-hapangama-foundation/supply-and-conservation-of-safe-water/">
                        Read More
                      </a>
                    </p>
                  </Parallax>
                </div>
              </div>
            </div>
          </div>

          {/* ......... 2 ...................................................................................... */}

          <div>
            <div className="two-column">
              <div className="column-2">
                {/* Column 1 */}
                <div style={{ marginTop: "-100px" }}>
                  <br />
                  <br />
                  <Parallax speed={30}>
                    <ParallaxBanner style={{ aspectRatio: "2 / 1.2" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/01.jpg"
                        speed={2}
                        style={{ backgroundSize: "cover" }}
                      />
                    </ParallaxBanner>
                    <br />
                    <a
                      href="/sustainability/lalith-hapangama-foundation/dedicated-health-care-initiatives/"
                      className="topic"
                    >
                      Dedicated Health Care Initiatives
                    </a>
                    <p className="para">
                      An estate medical officer has been engaged to overlook the
                      minor health care needs of the workers, in addition to
                      prescribing drugs for minor ailments. <br />
                      <br />
                    </p>
                    <p className="readmore">
                      <a href="/sustainability/lalith-hapangama-foundation/dedicated-health-care-initiatives/">
                        Read More
                      </a>
                    </p>

                    <div style={{ marginTop: "200px" }}>
                      <ParallaxBanner style={{ aspectRatio: "2 / 1.4" }}>
                        <ParallaxBannerLayer
                          image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/05.jpg"
                          speed={2}
                          style={{ backgroundSize: "cover" }}
                        />
                      </ParallaxBanner>
                      <br />
                      <h1 className="topic-2">Employee Housing Projects</h1>
                      <p className="para">
                        At Iona Estate land has been allocated for construction
                        of individual cottages under an employee empowerment
                        scheme. <br />
                        <br />
                      </p>
                    </div>
                  </Parallax>
                </div>
              </div>

              <div className="column-2">
                {/* Column 2 */}

                <br />
                <br />
                <br />
                <ParallaxBanner style={{ aspectRatio: "2 / 1.4" }}>
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/foundation/02.jpg"
                    speed={2}
                    style={{ backgroundSize: "cover" }}
                  />
                </ParallaxBanner>
                <br />
                <a
                  href="/sustainability/lalith-hapangama-foundation/supporting-education/"
                  className="topic"
                >
                  Supporting Education
                </a>
                <p className="para">
                  An on-going project is the provision of school books and
                  equipment to primary school students annually. <br />
                  <br />
                </p>
                <p className="readmore">
                  <a href="/sustainability/lalith-hapangama-foundation/supporting-education/">
                    Read More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ParallaxProvider>
    );
    }
    
    export default LHFmaincontent;